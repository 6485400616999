import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { CloudDownloadOutlined, FacebookOutlined, InstagramOutlined, MailOutlined } from '@ant-design/icons';
import { Layout, Divider, Button } from 'antd';
import LogoWithName from './assets/header-logo.svg';
import Home from './home/Home';
import PrivacyPolicy from './policy/Privacy';
import ComunityGuidelines from './policy/Community';
import AppUpdateNotice from './notice/AppRequireUpdate';
import AppDownloadNotice from './notice/AppDowload';
import OptionalAppUpdate from './notice/OptionalUpdate';
import Download from './download/Download';
import Donate from './donate/Donate';
import './App.css';

function Header(props) {
  const darkTheme = props.darkTheme;
  const style = {
    'position': 'fixed',
    'zIndex': 1,
    'width': '100%',
    'borderBottom': `1px solid ${(darkTheme) ? '#ffffff20' : '#00000020'}`,
    'backgroundColor': (darkTheme) ? '#25252590' : '#ffffff90',
    'backdropFilter': 'blur(8px) saturate(250%)',
    'WebkitBackdropFilter': 'blur(8px) saturate(250%)',
    'paddingLeft': '1.5em',
    'paddingRight': '1.5em'
  };

  return (
    <Layout.Header style={style}>
      <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between', }}>
        <a href='/home'>
          <img className='app-logo' src={LogoWithName} alt='Pandora: Anime & Social' />
        </a>
        <div>
          <Button type='primary' shape='round' size='middle' href='/download' disabled={props.location.pathname.startsWith('/download')}>
            <b>Descargas</b> <CloudDownloadOutlined />
          </Button>
        </div>
      </div>
    </Layout.Header>
  )
}

function Content() {
  return (
    <div className='app-content-body'>
      <Routes>
        <Route path='' element={<Navigate to='/home' />} />
        <Route path='/home' element={<Home />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/community-guidelines' element={<ComunityGuidelines />} />
        <Route path='/app-update-notice' element={<AppUpdateNotice />} />
        <Route path='/download' element={<Download />} />
        <Route path='/donate' element={<Donate />} />
        <Route path='/app-download-notice' element={<AppDownloadNotice />} />
      </Routes>
    </div>
  );
}

function Footer() {
  return (
    <div className='app-footer'>
      <Divider />
      <p>Pandora: Anime & Social © 2022 By JPH Labs</p>
      <p><a href='/privacy-policy'>Politica de Privacidad</a> • <a href='/community-guidelines'>Normas de comunidad</a></p>
      <p>El logotipo y nombre de 'Pandora: Anime & Social' son marcas comerciales de JPH Labs.</p>
      <p>Todas las demás marcas comerciales son propiedad de sus respectivos dueños.</p>
      <b>Contactanos:</b>
      <p><a href='https://www.instagram.com/pandora.anime/'>Instagram <InstagramOutlined /></a> • <a href='https://www.facebook.com/Pandora-Anime-100386975303257'>Facebook <FacebookOutlined /></a> • <a href='mailto:pandora.anime.app@gmail.com'>Email <MailOutlined /></a></p>
    </div>
  );
}

function App(props) {
  const location = useLocation();

  if (location.pathname.endsWith('/app-optional-update')) {
    return <OptionalAppUpdate />
  }

  if (location.pathname.endsWith('/app-update-notice')) {
    return <AppUpdateNotice />
  }

  if (location.pathname.endsWith('/app-download-notice')) {
    return <AppDownloadNotice />//<div><p>La version de la tienda no cuenta con esta funcionalidad, descargar de nuestra pagina oficial</p></div>
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header location={location} darkTheme={props.darkTheme} />
      <Content />
      <Footer />
    </Layout>
  )
}

export default () => {
  const darkTheme = false//window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

  return (
    <Router>
      <App darkTheme={darkTheme} />
    </Router>
  );
}
