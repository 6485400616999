import { useSearchParams } from 'react-router-dom';
import './AppRequireUpdate.css';

function AppDowloadNotice() {
  const [params, _] = useSearchParams();
  const clientVersion = (params.get('client-ver') || 0);
  const hint = (clientVersion < 34) ? <a href="https://www.pandora-anime.com/download">haz clic aqui</a> : 'busca en tu navegador web "pandora-anime"'
  return (
    <div id="container">
      <b>Esta funcionalidad solo esta disponible en la version completa de la app, en nuestra pagina oficial, para conseguirla {hint}</b>
    </div>
  );
}

export default AppDowloadNotice;