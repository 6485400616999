import { useEffect, useState, useMemo } from 'react';
import { Typography, Card, Button, Space, Affix, Divider } from 'antd';
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import Blush from '../assets/blush.png';

const { Title, Paragraph } = Typography;

function iosClient() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function AppStoreDownload() {
    return (
        <div>
            <Title level={3}>Pandora: Anime & Social</Title>
            <Paragraph>Fecha de lanzamiento estimada 2023/12/01</Paragraph>
            <Card.Meta
                title={
                    <div style={{ 'display': 'flex', 'flexDirection': 'column', 'alignItems': 'center' }}>
                        <a><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/es-mx" alt="Download on the App Store" style={{ 'height': '53px' }} /> </a>
                    </div>
                }
                description="App Store y el logotipo de App Store son marcas comerciales de Apple Inc."
            />
        </div>
    )
}

function GooglePlayDownload() {
    return (
        <div>
            <Title level={3}>Pandora Lite: Anime & Social</Title>
            <Paragraph type='warning' strong>¡Algunas funcionalidades se encuentran deshabilitadas en esta version!</Paragraph>
            <Paragraph>
                Debido a las politicas de esta tienda algunas caracteristicas como la visualizacion de contenido estan deshabilitadas, por lo tanto, te recomendamos descargar la version full a traves de la descarga directa para una experiencia completa.
            </Paragraph>
            <Card.Meta
                title={
                    <div style={{ 'display': 'flex', 'flexDirection': 'column', 'alignItems': 'center' }}>
                        <a href='https://play.google.com/store/apps/details?id=com.jph.pandora&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img alt='Disponible en Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/es-419_badge_web_generic.png' style={{ height: '83px' }} />
                        </a>
                    </div>
                }
                description="Google Play y el logotipo de Google Play son marcas comerciales de Google LLC." />
        </div>
    )
}

function WebsiteDownload() {
    return (
        <div>
            <Title level={3}>Pandora: Anime & Social</Title>
            <Paragraph>Esta version cuenta con funcionalidad completa, incluyendo la visualizacion de contenido.</Paragraph>
            <Paragraph strong>
                <b>Asegurate de seguir los siguientes pasos:</b>
                <ul>
                    <li>Densinstalar versiones previas de Pandora para evitar problemas de compatibilidad.</li>
                    <li>Permitir la instalacion de apps de fuentes externas.</li>
                </ul>
            </Paragraph>
            <Card.Meta
                title={
                    <div style={{ 'display': 'flex', 'flexDirection': 'column', 'alignItems': 'center' }}>
                        <a href='/app-release.apk' type='application/vnd.android.package-archive' download='pandora-v3-1-8.apk'>
                            <Button type='primary' shape='round' size='large'>
                                <b>Descargar</b> <AndroidOutlined />
                            </Button>
                        </a>
                    </div>
                }
                description={<p>Asegurate de haber seguido los pasos detallados en la parte superior, si no logras instalar el APK de manera correcta contactanos via <a href='mailto:pandora.anime.app@gmail.com?subject=Problema con la instalacion APK'> email</a>.</p>}
            >
            </Card.Meta >
        </div>
    )
}

function layoutDirection(width) {
    return (width <= 1080) ? 'column' : 'row'
}

function Download() {
    const [layout, setLayout] = useState(layoutDirection(window.innerWidth));
    const ios = iosClient();

    useEffect(() => {
        window.addEventListener('resize', () => {
            setLayout(layoutDirection(window.innerWidth));
        })
    }, []);

    const ListDivider = (layout === 'column') ? <Divider /> : <div style={{ 'width': '3em' }} />;

    return (
        <div>
            <div size='large' style={{ 'display': 'flex', 'flexDirection': layout, 'justifyContent': 'center' }}>
                {ios ? <AppStoreDownload /> : <WebsiteDownload />}
                {ListDivider}
                <GooglePlayDownload />
                {ListDivider}
                {ios ? <WebsiteDownload /> : <AppStoreDownload />}
            </div>
            <Divider />
            <Title level={3}>¡Ayudanos a seguir mejorando la aplicación! 💗🚀</Title>
            <Paragraph>
                Queremos seguir trayendote lo mejor del Anime, pero planeamos expandir el contenido con Mangas, debido a esto necesitamos mas recursos de lo normal para continuar el desarrollo de la app y servidores, tambien tenemos pensado traer la plataforma a la web y necesitamos de tu apoyo para conseguir eso {"<3"}.
            </Paragraph>
            <Paragraph>
                Si no puedes ayudarnos con una pequeña donacion, recuerda que tambien nos ayudas en gran manera si compartes la app con tus amigos ;)
            </Paragraph>
            <Affix offsetBottom={10} style={{ 'bottom': 0, 'left': 10, 'position': 'sticky' }}>
                <div>
                    <a href="https://www.buymeacoffee.com/pandoradevs"><img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=pandoradevs&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff" /></a>
                    <img src={Blush} style={{ 'width': '50px' }}></img>
                </div>
            </Affix>
        </div >
    );
}


export default Download;