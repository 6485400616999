import { Typography } from 'antd';
const { Title, Paragraph } = Typography;

function ComunityGuidelines() {
    return (
        <>
            <Title style={{ textAlign: 'center' }}>Normas de la comunidad.</Title>
            <Paragraph>
                Creamos las normas comunitarias para establecer una guia que nuestros usuarios puedan utilizar para ayudarnos a fomentar un ambiente sano para todos. <b>Al utilizar Pandora aceptas estas normas y nuestra <a href='/privacy-policy'>politica de privacidad</a></b>.
                El incumplimiento de estas normas puede provocar la suspension temporal o permanente de la cuenta involucrada.
            </Paragraph>
            <Paragraph>
                Nuestra comunidad es diversa de culturas, edades y creencias; por lo tanto dedicamos mucho tiempo a considerar los factores que contribuyen a crear un entorno en el que todos se sientan seguros y libres de expresar sus opiniones.
            </Paragraph>
            <Paragraph>
                Queremos que Pandora sea una comunidad sana y segura para los amantes del anime y la cultura asociada en general. Ayúdanos a fomentar esta comunidad. Dirigete con respeto hacia los demas usuarios; no incites al odio y evita entrar en conflictos por gustos personales.
            </Paragraph>

            <Title level={3}>Haz interacciones relevantes y genuinas.</Title>
            <Paragraph>Con el fin de ayudarnos a erradicar el spam, no hagas que tus Me gusta aumenten de manera artificial, así tampoco publiques comentarios repetitivos. No publiques contenido que incluya, promocione, motive, facilite ni admita la oferta, la solicitud o el intercambio de calificaciones u opiniones de usuarios falsas o engañosas.</Paragraph>
            <Paragraph>No te hagas pasar por otras personas ni crees cuentas con el propósito de infringir nuestras normas o engañar a otros.</Paragraph>

            <Title level={3}>No promuevas ni practiques las siguientes actividades.</Title>

            <Paragraph>
                En Pandora, no se aceptan actividades ni personas que apoyen o elogien el terrorismo, el crimen organizado o grupos que promuevan el odio.
                No mostramos ningún tipo de tolerancia con aquellas personas que compartan contenido sexual relacionado con menores o que amenacen con publicar imágenes íntimas de otros usuarios.
                Tampoco toleramos que se utilize nuestra plataforma como medio para compartir enlaces de otras redes sociales con el fin de realizar una de las actividades anteriormente mencionadas.
            </Paragraph>

            <Title level={3}>Respeta a los miembros de la comunidad de Pandora.</Title>
            <Paragraph>
                Resulta inaceptable fomentar la violencia o atacar a alguien por razones de raza, etnia, nacionalidad, sexo, identidad sexual, orientación sexual, creencias religiosas, discapacidad o enfermedad. Cabe la posibilidad de que permitamos el uso de lenguaje que incite al odio si se utiliza con el fin de cuestionar este tipo de comportamientos o para poner de manifiesto estos temas. En estos casos, te pedimos que expreses tu intención de forma clara.
            </Paragraph>

            <Title level={3}>Contribuye a mantener nuestro entorno seguro evitando fomentar las autolesiones.</Title>
            <Paragraph>
                Animar o instar a personas a autolesionarse va en contra de este entorno de apoyo, por lo que retiraremos o inhabilitaremos las cuentas que reciban reportes en este sentido.
            </Paragraph>

            <Title level={3}>Contactanos</Title>
            <Paragraph>
                Si tienes alguna duda o crees que nos puedes ayudar a mejorar la comunidad de alguna manera, puedes contactarnos via <a href='mailto:pandora.anime.app@gmail.com?subject=Consulta sobre las guias de comunidad'>email</a>.
            </Paragraph>
        </>
    );
}

export default ComunityGuidelines;