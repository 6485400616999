import './Home.css';
import { useState, useEffect } from 'react';
import { Carousel, Affix, Button } from 'antd';
import poster_1 from '../assets/1.jpg';//const poster_1 = 'https://pandora-builds.s3.sa-east-1.amazonaws.com/assets/1.jpg';
import poster_2 from '../assets/2.jpg';//const poster_2 = 'https://pandora-builds.s3.sa-east-1.amazonaws.com/assets/2.jpg';
import poster_3 from '../assets/3.jpg';//const poster_3 = 'https://pandora-builds.s3.sa-east-1.amazonaws.com/assets/3.jpg';
import poster_4 from '../assets/4.jpg';//const poster_4 = 'https://pandora-builds.s3.sa-east-1.amazonaws.com/assets/4.jpg';
import poster_5 from '../assets/5.jpg';//const poster_5 = 'https://pandora-builds.s3.sa-east-1.amazonaws.com/assets/5.jpg';

function Home() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => { setWidth(window.innerWidth) });
    }, []);

    return (
        <div style={{ width: '100%', padding: '-50px' }}>
            <Carousel autoplay slidesToShow={Math.round(width / 480)} centerMode={true} >
                <div id="poster">
                    <img src={poster_1} alt="" />
                </div>
                <div id="poster">
                    <img src={poster_2} alt="" />
                </div>
                <div id="poster">
                    <img src={poster_3} alt="" />
                </div>
                <div id="poster">
                    <img src={poster_4} alt="" />
                </div>
                <div id="poster">
                    <img src={poster_5} alt="" />
                </div>
            </Carousel>
        </div>
    );
}

export default Home;