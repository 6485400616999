import './AppRequireUpdate.css';

function AppUpdateNotice() {
    return (
        <div id="container">
            <h1>¡Actualizacion de Pandora!</h1>
            <b>Para continuar viendo los episodios sigue estos pasos:</b>
            <ul>
                <li>Elimina la version que tienes instalada <b>(*IMPORTANTE*)</b></li>
                <li>Descarga la nueva version en: <a href="https://www.pandora-anime.com">https://www.pandora-anime.com</a> <b>(Si el link no se abre copiar y pegar en tu navegador)</b></li>
                <li>Instala el apk, inicia sesion y listo! :)</li>
            </ul>
            <p>Contactanos:</p>
            <p>Instagram: @pandora.anime</p>          
        </div>
    );
}

export default AppUpdateNotice;
