import { Button, Card } from "antd";
import Title from "antd/lib/typography/Title";

function Donate() {
    return (
        <div>
            <Title level={3}>{"Enlaces de donacion"}</Title>
            <p>{"Si te gusto la app, nos puedes apoyar con una pequeña donacion para seguir desarrollando y mejorando el proyecto <3"}</p>
            <div style={{ 'display': 'flex', 'flexDirection': 'row' }}>
                <a href="https://www.buymeacoffee.com/pandoradevs"><img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=pandoradevs&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff" /></a>
            </div>
        </div>
    )
}

export default Donate;