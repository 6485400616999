import { Typography } from 'antd';
const { Title, Paragraph } = Typography;

function Privacy() {
    return (
        <>
            <Title style={{ textAlign: 'center' }}>Politica de Privacidad</Title>
            <Paragraph>Esta política de privacidad rige el uso de la aplicación de software "Pandora: Anime & Social" para dispositivos móviles que fue creada por JPH Labs.</Paragraph>

            <Title level={3}>¿Qué información obtiene la Aplicación y cómo se utiliza?</Title>
            <Title level={5}>1. Información proporcionada por el usuario</Title>
            <Paragraph>La Aplicación obtiene la información que usted proporciona cuando descarga y registra la Aplicación. El registro con nosotros es opcional. Sin embargo, tenga en cuenta que es posible que no pueda utilizar algunas de las funciones que ofrece la Aplicación a menos que se registre con nosotros.</Paragraph>
            <Paragraph>Cuando se registra con nosotros y utiliza la Aplicación, generalmente proporciona (a) su nombre, dirección de correo electrónico, edad, nombre de usuario, contraseña y otra información de registro; (b) información relacionada con la transacción, como cuando realiza compras, responde a cualquier oferta o descarga o usa nuestras aplicaciones; (c) la información que nos proporciona cuando se comunica con nosotros para solicitar ayuda; (d) información de la tarjeta de crédito para la compra y uso de la Aplicación, y; (e) información que ingresa en nuestro sistema cuando usa la Aplicación, como información de contacto e información de gestión de proyectos.</Paragraph>
            <Paragraph>También podemos utilizar la información que nos proporcionó para comunicarnos con usted de vez en cuando para brindarle información importante, avisos obligatorios y promociones de marketing.</Paragraph>

            <Title level={5}>2. Información recopilada automáticamente</Title>
            <Paragraph>Además, la Aplicación puede recopilar cierta información automáticamente, que incluye, entre otros, el tipo de dispositivo móvil que utiliza, el ID único de dispositivo de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo móvil, el tipo de dispositivo móvil Los navegadores de Internet que utiliza e información sobre la forma en que utiliza la Aplicación.</Paragraph>

            <Title level={3}>¿La aplicación recopila información precisa sobre la ubicación en tiempo real del dispositivo?</Title>
            <Paragraph>Esta aplicación no recopila información precisa sobre la ubicación de su dispositivo móvil.</Paragraph>
            <Title level={5}>¿Los terceros ven y / o tienen acceso a la información obtenida por la Aplicación?</Title>
            <Paragraph>Solo los datos agregados y anonimizados se transmiten periódicamente a servicios externos para ayudarnos a mejorar la Aplicación y nuestro servicio. Compartiremos su información con terceros solo en las formas que se describen en esta declaración de privacidad.</Paragraph>

            <Title level={5}>Podemos divulgar información proporcionada por el usuario y recopilada automáticamente:</Title>
            <Paragraph>
                <ul>
                    <li>según lo requiera la ley, como para cumplir con una citación o un proceso legal similar;</li>
                    <li>cuando creemos de buena fe que la divulgación es necesaria para proteger nuestros derechos, proteger su seguridad o la seguridad de otros, investigar un fraude o responder a una solicitud del gobierno;</li>
                    <li>con nuestros proveedores de servicios de confianza que trabajan en nuestro nombre, no tienen un uso independiente de la información que les revelamos y hemos acordado adherirse a las reglas establecidas en esta declaración de privacidad.</li>
                    <li>si JPH Labs está involucrado en una fusión, adquisición o venta de la totalidad o una parte de sus activos, se le notificará por correo electrónico y / o un aviso destacado en nuestro sitio web de cualquier cambio en la propiedad o usos de esta información, como así como las opciones que pueda tener con respecto a esta información.</li>
                </ul>
            </Paragraph>

            <Title level={3}>Política de retención de datos y gestión de su información</Title>
            <Paragraph>Conservaremos los datos proporcionados por el usuario durante el tiempo que utilice la Aplicación y, posteriormente, durante un tiempo razonable. Retendremos la información recopilada automáticamente durante un máximo de 24 meses y, a partir de entonces, podemos almacenarla en conjunto. Si desea que eliminemos los Datos proporcionados por el usuario que ha proporcionado a través de la Aplicación, comuníquese con nosotros a <a href='mailto:pandora.anime.app@gmail.com'>pandora.anime.app@gmail.com</a> y le responderemos en un tiempo razonable. Tenga en cuenta que algunos o todos los Datos proporcionados por el usuario pueden ser necesarios para que la Aplicación funcione correctamente.</Paragraph>


            <Title level={3}>Menores</Title>
            <Paragraph>No utilizamos la Aplicación para solicitar a sabiendas datos de niños menores de 13 años o comercializarlos con ellos. Si un padre o tutor se da cuenta de que su hijo nos ha proporcionado información sin su consentimiento, debe comunicarse con nosotros en <a href='mailto:pandora.anime.app@gmail.com'>pandora.anime.app@gmail.com</a>. Eliminaremos dicha información de nuestros archivos dentro de un tiempo razonable.</Paragraph>

            <Title level={3}>Seguridad</Title>
            <Paragraph>Nos preocupa salvaguardar la confidencialidad de su información. Proporcionamos salvaguardias físicas, electrónicas y de procedimiento para proteger la información que procesamos y mantenemos. Por ejemplo, limitamos el acceso a esta información a los empleados y contratistas autorizados que necesitan conocer esa información para operar, desarrollar o mejorar nuestra Aplicación. Tenga en cuenta que, aunque nos esforzamos por proporcionar una seguridad razonable para la información que procesamos y mantenemos, ningún sistema de seguridad puede evitar todas las posibles infracciones de seguridad.</Paragraph>

            <Title level={3}>Cambios</Title>
            <Paragraph>Esta Política de privacidad puede actualizarse de vez en cuando por cualquier motivo. Le notificaremos de cualquier cambio en nuestra Política de privacidad mediante la publicación de la nueva Política de privacidad <a href='https://www.pandora-anime.com/privacy-policy'>https://www.pandora-anime.com/privacy-policy</a> y le informaremos por correo electrónico o mensaje de texto. Se le recomienda que consulte esta Política de privacidad con regularidad para conocer cualquier cambio, ya que el uso continuo se considera una aprobación de todos los cambios.</Paragraph>

            <Title level={3}>Tu consentimiento</Title>
            <Paragraph>Al utilizar la Aplicación, usted da su consentimiento para que procesemos su información según lo establecido en esta Política de privacidad ahora y según lo enmendado por nosotros. "Procesamiento" significa usar cookies en una computadora/dispositivo portátil o usar o tocar información de cualquier manera, que incluye, entre otros, la recopilación, el almacenamiento, la eliminación, el uso, la combinación y la divulgación de información.</Paragraph>

            <Title level={3}>Contactanos</Title>
            <Paragraph>Si tiene alguna pregunta sobre la privacidad mientras usa la Aplicación, o tiene preguntas sobre nuestras prácticas, comuníquese con nosotros por correo electrónico a <a href='mailto:pandora.anime.app@gmail.com'>pandora.anime.app@gmail.com</a>.</Paragraph>
        </>
    );
}

export default Privacy;