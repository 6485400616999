import { useSearchParams } from 'react-router-dom';
import { Affix, Button, Typography } from 'antd';

function UpdateActions() {
    const [params, _] = useSearchParams();
    const clientVersion = (params.get('client-ver') || 0);
    const redirect = () => {
        let link = params.get('og-link');
        window.location.replace(link);
    }

    // Olders clients cannot update by button.
    if (clientVersion < 29) {
        return (
            <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between' }}>
                <p>Descargalo en: <strong style={{ 'color': 'blueviolet' }}>www.pandora-anime.com</strong></p>
                <Button shape='round' onClick={redirect}>Mas tarde</Button>
            </div>
        )
    }

    return (
        <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between' }}>
            <Button shape='round' onClick={redirect}><b>Mas tarde</b></Button>
            <Button type='primary' shape='round' href='https://www.pandora-anime.com/download?native_browser=true'><b>Descargar</b></Button>
        </div>
    )
}

function OptionalAppUpdate() {
    const darkTheme = true;
    return (
        <div style={{ 'padding': '1.5em', 'width': '100vw', 'height': '100vh    ', 'display': 'flex', 'flexDirection': 'column', 'alignContent': 'center' }}>
            <Typography.Title level={3}>Actualizacion disponible</Typography.Title>

            <Typography.Paragraph>
                <Typography.Title level={5}>Cambios de version 3.1.8:</Typography.Title>
                <ul>
                    <li>Minimizamos la cantidad y reorganizamos los anuncios para evitar clicks no intencionales.</li>
                    <li>Solucionamos un error que no permitia añadir mas contenido a la coleccion.</li>
                    <li>Ahora la lista de coleccion aparece en orden cronologico.</li>
                    <li>Rediseñamos la pantalla de coleccion.</li>
                </ul>
                <Typography.Paragraph type='secondary'>
                    La coleccion solo carga los ultimos 50 elementos añadidos, solucionaremos este inconveniente en las siguientes actualizaciones.
                </Typography.Paragraph>
            </Typography.Paragraph>

            <Typography.Paragraph>
                <Typography.Title level={5}>Cambios de version 3.1.6:</Typography.Title>
                <ul>
                    <li>Notificacion de episodios restaurada.</li>
                    <li>Mejoras de estabilidad y reproduccion de video.</li>
                    <li>Solucionamos un error que no permitia cerrar los anuncios.</li>
                </ul>
            </Typography.Paragraph>

            <Affix offsetBottom={0} style={{ 'position': 'sticky' }}>
                <div style={{
                    'borderTop': `1px solid ${(darkTheme) ? '#ffffff20' : '#00000020'}`,
                    'backgroundColor': (darkTheme) ? '#25252590' : '#ffffff90',
                    'backdropFilter': 'blur(8px) saturate(250%)',
                    'WebkitBackdropFilter': 'blur(8px) saturate(250%)',
                    'marginRight': '-1.5em',
                    'marginLeft': '-1.5em',
                    'padding': '0.5em',
                }}>
                    <UpdateActions />
                </div>
            </Affix>
        </div>
    )
}

export default OptionalAppUpdate;